import * as React from "react"
import PropTypes from "prop-types"

const CheckboxOptions = ({ option, form, name, onChange }) => {
  const onChangeHandler = e => {
    if (onChange) {
      onChange(e, name)
    }
  }
  return (
    <label className="flex flex-row items-center gap-2">
      <input
        type="checkbox"
        className="peer m-2 h-4 w-4 translate-y-[.25rem] border border-gray-400 accent-red-700 sm:h-5 sm:w-5"
        value={option}
        onChange={onChangeHandler}
        checked={form[name]}
      />
      <p className=" inline-block text-gray-400 hover:text-black peer-checked:text-black ">
        {option}
        {form[name]}
      </p>
    </label>
  )
}
CheckboxOptions.propTypes = {
  option: PropTypes.string.isRequired,
  form: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func,
}

export default CheckboxOptions
