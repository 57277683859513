import * as React from "react"
import { Link } from "gatsby"

const FAQItem = ({ title, children }) => {
  return (
    <div className="grid grid-cols-1 gap-4 p-3">
      <h2>{title}</h2>
      <p>{children}</p>
    </div>
  )
}

const FAQCard = () => {
  return (
    <>
      <FAQItem title="What services do we offer?">
        <p>
          We offer a wide range of services;
          <Link
            className="text-accent"
            to={"/selling-your-jewelry-locally-and-beyond-with-sell-us-your-jewelry-com/"}
          >
            {" "}
            we buy outright
          </Link>
          followed by immediate payment, we
          <Link className="text-accent" to={"/blog/the-benefits-of-consigning-luxury-jewelry/"}>
            {" "}
            consign
          </Link>{" "}
          and
          <Link className="text-accent" to={"/trade-in-your-jewelry/"}>
            {" "}
            trade
          </Link>
          jewelry, luxury watches, branded designer jewelry, gold and platinum jewelry, gold coins,
          diamonds, sterling silver flatware, and hollowware.
        </p>
      </FAQItem>

      <FAQItem title="How do I sell my jewelry?">
        <p className="mb-2">At SellUsYourJewelry.com you have two options to choose from:</p>
        <ul className="list-disc ml-8 mb-2">
          <li>
            <Link className="text-accent" to={"/how-it-works/"}>
              Sell online
            </Link>
            : You can sell from the comfort of your home with our easy online selling process.
          </li>
          <li>
            <a
              className="text-accent"
              href="https://maps.app.goo.gl/A3XgDm59Jk4R6kZcA"
              target="_blank"
              rel="noopener noreferrer"
            >
              In person
            </a>
            : Visit our showroom located at 9595 Harding Ave, Surfside/Bal Harbour Fl 33154.
          </li>
        </ul>
      </FAQItem>

      <FAQItem title="How much is my watch worth?">
        <p>
          Determining what your watch is worth requires professional expertise. Factors we consider
          include brand, model specifics, production year, condition, and more.
        </p>
      </FAQItem>

      <FAQItem title="How to get a FREE watch quote?">
        <p>
          Visit{" "}
          <Link className="text-accent" to={"/what-is-my-watch-worth/"}>
            https://sellusyourjewelry.com/what-is-my-watch-worth/
          </Link>{" "}
          and complete the online form detailing information on your watch.
        </p>
      </FAQItem>

      <FAQItem title="How to get a FREE jewelry quote?">
        <p>
          Visit{" "}
          <Link className="text-accent" to={"/what-is-my-jewelry-worth/"}>
            https://sellusyourjewelry.com/what-is-my-jewelry-worth/
          </Link>{" "}
          and complete the online form with details about your jewelry.
        </p>
      </FAQItem>

      <FAQItem title="How much is my jewelry worth?">
        <p>
          Determining the value of your jewelry depends on factors like metal type, gemstones,
          designer or brand, condition, market demand, and more.
        </p>
      </FAQItem>

      <FAQItem title="What do we buy?">
        <p>
          We buy fine signed and estate jewelry, branded designer jewelry, luxury branded watches,
          diamonds, gemstones, sterling silver, and more.{" "}
          <Link className="text-accent" to={"/brands-we-buy/"}>
            See our Full list of specific brands we buy.
          </Link>
        </p>
      </FAQItem>

      <FAQItem title="Why sell to SellUsYourJewelry.com?">
        <p>
          SellUsYourJewelry.com combines the convenience of an online platform with the trust of a
          physical location. We have over 43 years of experience in buying, consigning, and
          repairing luxury jewelry and watches.
        </p>
      </FAQItem>

      <FAQItem title="What if my jewelry is broken or has missing parts?">
        <p>
          We also buy jewelry that is in less-than-perfect condition or may be beyond repair.{" "}
          <Link className="text-accent" to={"/what-is-my-jewelry-worth/"}>
            Fill out our free-quote form
          </Link>{" "}
          to find out if your item can be sold.
        </p>
      </FAQItem>

      <FAQItem title="What documents do I need to sell my jewelry, watches, or diamonds?">
        <p>
          You don’t need any documents to sell, but having original boxes, receipts, or
          authentication certificates can help fetch a higher price.
        </p>
      </FAQItem>

      <FAQItem title="Is my jewelry safe with your company?">
        <p>
          We are fully insured and bonded. Once your items are in our possession, they are fully
          insured and handled with utmost care.
        </p>
      </FAQItem>

      <FAQItem title="How fast will I get paid?">
        <p>
          Payment is immediate upon completing an in-person transaction or within 48 hours via check
          or wire transfer for online transactions.
        </p>
      </FAQItem>

      <FAQItem title="Do you price match?">
        <p>
          We strive to beat other offers. Our motto is “come see us last for the highest offer.”
        </p>
      </FAQItem>

      <FAQItem title="How do I ship my watch or jewelry to you?">
        <p>
          We provide pre-packed shipping materials, detailed instructions, and insurance to ensure
          your items are safely delivered to us.
        </p>
      </FAQItem>

      <FAQItem title="Which watches sell best?">
        <p>
          Pre-owned luxury watches like Rolex, Patek Philippe, Audemars Piguet, and Omega are highly
          sought after and sell well.
        </p>
      </FAQItem>

      <FAQItem title="How do I find pre-owned jewelry buyers near me to sell my Rolex or other luxury watches?">
        <p>
          SellUsYourJewelry.com is a trusted choice for selling luxury watches and jewelry. Visit
          our showroom or sell online from anywhere in the country.
        </p>
      </FAQItem>

      <FAQItem title="How can I sell my Rolex or watch near me?">
        <p>
          SellUsYourJewelry.com offers online nationwide options and in-person transactions at our
          South Florida showroom.
        </p>
      </FAQItem>

      <FAQItem title="How can I find a reliable watch buyer?">
        <p>
          SellUsYourJewelry.com has over 43 years of experience and is known for transparent
          processes and competitive offers.
        </p>
      </FAQItem>
    </>
  )
}

export default FAQCard
