const getType = obj => {
  if (obj == null) {
    return (obj + "").toLowerCase()
  } // implicit toString() conversion
  const deepType = Object.prototype.toString.call(obj).slice(8, -1).toLowerCase()
  if (deepType === "generatorfunction") {
    return "function"
  }
  // ** Prevent overspecificity (for example, [object HTMLDivElement], etc).
  // ** Account for functionish Regexp (Android <=2.3), functionish <object> element (Chrome <=57, Firefox <=52), etc.
  // **  String.prototype.match is universally supported.
  // ** return deepType.match(/^(array|bigint|date|error|function|generator|regexp|symbol)$/) ? deepType :
  // **    (typeof obj === 'object' || typeof obj === 'function') ? 'object' : typeof obj;
  return deepType
}

const isFile = obj => {
  return getType(obj) === "file"
}

const mockFile = file => {
  return {
    status: "uploading",
    lastModified: file.lastModified,
    name: file.name,
    size: file.size,
    type: file.type,
  }
}

const extractFilesAndForm = (form, name) => {
  const files = []
  const formData = {
    ...form,
    _formName: name,
    _qs: window?.qs,
    _initial_page: window?._initial_page,
  }
  delete formData[".errors"]
  Object.entries(form).forEach(([key, value]) => {
    if (Array.isArray(value)) {
      formData[key] = []
      for (let index in value) {
        let item = value[index]
        if (isFile(item)) {
          files.push({ file: item, key, index })
          formData[key][index] = mockFile(item)
        } else {
          formData[key][index] = item
        }
      }
    } else {
      if (isFile(value)) {
        files.push({ file: value, key, index: false })
        formData[key] = mockFile(value)
      }
    }
  })
  return { formData, files }
}

export default extractFilesAndForm
