import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { Link, useStaticQuery, graphql } from "gatsby"
import { IconRing } from "../icons/Icons"
import Testimonials from "../common/Testimonials"
import YouTube from "../common/YouTube"
import Map from "../common/Map"
import HeroImage from "../HeroImage"
import HowItWorks from "../common/HowItWorks"
import FAQs from "../common/FAQs"

const IndexBelowTheFold = () => {
  const { watches, jewelry, blogs } = useStaticQuery(
    graphql`
      query CombinedHomepageQuery {
        blogs: allStrapiPage(
          filter: { press_release: { eq: true } }
          sort: { order: DESC, fields: updated_at }
          limit: 3
        ) {
          nodes {
            title
            meta_title
            meta_description
            slug
            hero_image_url
            hero_image {
              url
            }
            created_at
            updated_at
          }
        }
        watches: allStrapiPage(
          filter: { tag: { watches: { eq: true } } }
          sort: { fields: tag___brand }
        ) {
          nodes {
            slug
            tag {
              brand
            }
          }
        }
        jewelry: allStrapiPage(
          filter: { tag: { jewelry: { eq: true } } }
          sort: { fields: tag___brand }
        ) {
          nodes {
            slug
            tag {
              brand
            }
          }
        }
      }
    `
  )

  const letters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ".split("")
  const watchesByLetter = {}
  const jewelryByLetter = {}
  watches.nodes.forEach(watch => {
    const letter = watch?.tag?.brand?.[0]?.toUpperCase()
    if (!letter) return
    if (!watchesByLetter[letter]) {
      watchesByLetter[letter] = []
    }
    watchesByLetter[letter].push(watch)
  })
  jewelry.nodes.forEach(jewelry => {
    const letter = jewelry?.tag?.brand?.[0]?.toUpperCase()
    if (!letter) return
    if (!jewelryByLetter[letter]) {
      jewelryByLetter[letter] = []
    }
    jewelryByLetter[letter].push(jewelry)
  })

  return (
    <>
      <hr className="w-10/12 mx-auto mt-5" />
      <HowItWorks className={"w-10/12 mx-auto max-w-[1366px] xl:my-40"} />
      <hr className="w-10/12 mx-auto mt-5" />
      <section className=" grid grid-cols-1 lg:grid-cols-2 w-10/12 mx-auto">
        <div className="w-full flex flex-col  items-center gap-5">
          <h2 className="p-10 text-center text-3xl uppercase  mx-auto">
            We Buy All Types of Jewelry
          </h2>
          <p className="max-w-[1024px] mx-auto text-center leading-relaxed ">
            From diamond engagement rings to vintage brooches, we buy a wide range of jewelry
            pieces, including brands like Tiffany & Co., Cartier, Van Cleef & Arpels, and David
            Yurman. Get a free, no-obligation quote today and see how much your piece of jewelry is
            worth.
          </p>
          <YouTube
            src={"https://www.youtube.com/embed/eqmFkSMB7co?si=iNrNNMXQTQK15P_V?rel=0&mute=0"}
            title={"How To Sell Your Jewelry and Watches"}
            className={"aspect-video w-full max-w-[1000px] sticky top-14 "}
          />
        </div>
        <div className="w-full pb-10">
          <h2 className="p-10 text-center text-2xl uppercase  mx-auto">
            Are you planning to sell your jewelry or watch online?
          </h2>
          <p className="max-w-[800px] mx-auto leading-relaxed ">
            Sell Us Your Jewelry is the quickest and most convenient online buying platform a highly
            regarded buying division of Gray and Sons Jewelers is known not only in South Florida
            but worldwide as a #1 luxury <Link to="/luxury-jewelry-buyer/">jewelry buyer</Link>,{" "}
            <Link to="/used-luxury-watch-buyer/">fine watch buyer</Link>,{" "}
            <Link to="/bal-harbour-diamond-buyer/">diamond buyer</Link>,{" "}
            <Link to="/sterling-silver-buyer-near-me/">sterling silver buyer</Link> and{" "}
            <Link to="/miami-buy-gold-near-me/">gold buyer</Link>.
            <br />
            <br />
            <strong>SELL * CONSIGN * TRADE</strong> Estate, Vintage, Brand Designer Jewelry & Luxury
            Pre- Owned Watches.
          </p>
          <h2 className="p-10 text-center text-2xl uppercase  mx-auto">
            Why Sell Us Your Jewelry?
          </h2>
          <p className="max-w-[800px] mx-auto leading-relaxed ">
            <ul className="list-disc pl-5 space-y-4 mb-5">
              <li>
                <b>Proven record:</b> Over $350 million paid out to satisfied customers over 45
                years in business, making us one of the most trusted names in the industry.
              </li>
              <li>
                <b>Highly Trusted:</b> 45+ years in Business of Buying, Trading and Consigning
                Luxury Watches, Estate Jewelry, Diamonds, Sterling Silver, Gold and Platinum.
                We&apos;re not a pop-up store—our decades standing physical location, monthly
                catalog, and online platforms have served millions of customers all over the world.
              </li>
              <li>
                <b>Experience:</b> Top Estate Jewelry & Fine Watch Expertise; Our In-House Team of
                Experts and Watchmakers has a combined over 200 years of expertise and unmatched
                skills in evaluating your valuables.
              </li>
              <li>
                <b>Free Watch and Jewelry Estimates:</b> Get True Market Value. Discover how much
                your watch & jewelry is worth.
              </li>
              <li>
                <b>Highest Offers:</b> We offer top prices for your luxury items. Be sure to visit
                us last to ensure you get the best deal.
              </li>
              <li>
                <b>Immediate Payments:</b> via check, bank wire, or cash.
              </li>
              <li>
                <b>Convenience:</b> <u>Sell online</u> from the comfort of your home
                <div className="text-center">or</div>
                <u>Sell in-person</u>. Cash Out Today at our Miami Beach showroom. No appointment
                required.
              </li>
              <li>
                <b>Exceptional Customer Service:</b> Real Specialists on-site.
                <br />
              </li>
            </ul>
            We speak Spanish, Russian, French, Polish, and Portuguese. We are on-site 6 days a week
            and during Holidays even 7 days a week. We are just a phone call away.{" "}
            <a href="tel:+13057706955" className="text-blue-500 underline">
              305-770-6955
            </a>
          </p>
          <h2 className="p-10 text-center text-2xl uppercase  mx-auto">
            Best Place To Sell Your Jewelry
          </h2>
          <p className="max-w-[800px] mx-auto leading-relaxed ">
            Today Gray and Son Jewelers along with their buying division Sell US Your Jewelry.com is
            a global leader in buying, selling, trading, consigning and expertly repairing the
            world’s most luxurious watches and fine jewelry.
            <br />
            <br />
            45 years ago we began to distinguish ourselves from other dealers through wide range of
            services starting with meticulous restoration of fine watches, offering one of a kind
            award winning 76 page catalog and later on venturing into digital era with online buying
            platform SellUsYourJewelry.com
            <br />
            <br />
            Thousands of customers from Florida, Dallas, Boston, Chicago, Philadelphia, Los Angeles,
            Washington DC, Cleveland Ohio, New York, Las Vegas, California, Hartford Connecticut,
            and the rest of the United States use our online platform to Sell, Consign and Trade
            each year.
          </p>
        </div>
      </section>

      <section className="bg-yellow-50 py-20 w-full flex flex-col lg:flex-row relative lg:px-5">
        <div className=" w-full h-full max-w-max mx-auto lg:sticky top-5">
          <StaticImage
            src={"../../images/top-watch-buyer-img.png"}
            alt={"Rolex, Patek Philippe, GIA Diamond Ring and Studs Buyer"}
            className={"object-contain aspect-square  h-full"}
          />
        </div>
        <section className="w-full">
          <div className="grid xl:grid-cols-2 p-3 w-10/12 mx-auto max-w-[1000px]">
            <div className="col-span-2 border-t my-4 border-accent">
              <h2
                className={
                  "text-center text-accent max-w-max px-3 py-1 -translate-y-5 border bg-yellow-50 border-accent mx-auto"
                }
              >
                Watch Brands
              </h2>
              <div className={"columns-2 xl:columns-3 2xl:columns-4"}>
                {letters
                  .filter(letter => watchesByLetter[letter] && watchesByLetter[letter].length)
                  .map(letter => {
                    return (
                      <div key={letter} className={"break-inside-avoid-column"}>
                        <h3 className={"my-2"}>{letter}</h3>
                        <ul>
                          {watchesByLetter[letter] &&
                            watchesByLetter[letter].map(watch => {
                              return (
                                <li key={watch.slug} className="my-5 lg:my-2">
                                  <Link to={`/${watch.slug}/`}>{watch.tag.brand}</Link>
                                </li>
                              )
                            })}
                        </ul>
                      </div>
                    )
                  })}
              </div>
            </div>
            <div className="col-span-2 border-t my-4 border-accent ">
              <h2
                className={
                  "text-center text-accent max-w-max px-3 py-1 -translate-y-5 border bg-yellow-50 mx-auto border-accent"
                }
              >
                Jewelry Brands
              </h2>
              <div className={"columns-2 lg:columns-3 xl:columns-4"}>
                {letters
                  .filter(letter => jewelryByLetter[letter] && jewelryByLetter[letter].length)
                  .map(letter => {
                    return (
                      <div key={letter} className={"break-inside-avoid-column"}>
                        <h3 className={"my-2"}>{letter}</h3>
                        <ul>
                          {jewelryByLetter[letter] &&
                            jewelryByLetter[letter].map(watch => {
                              return (
                                <li key={watch.slug} className="my-5 lg:my-2">
                                  <Link to={`/${watch.slug}/`}>{watch.tag.brand}</Link>
                                </li>
                              )
                            })}
                        </ul>
                      </div>
                    )
                  })}
              </div>
              <div className="col-span-2 border-t my-10 border-accent">
                <h2
                  className={
                    "text-center text-accent max-w-max px-3 py-1 -translate-y-5 border bg-yellow-50 border-accent mx-auto"
                  }
                >
                  Other Luxury Goods
                </h2>
                <ul className="md:columns-2 lg:columns-3 xl:columns-4">
                  <li className="h-12 my-3">
                    <Link to={"/sell-diamond-rings-now/"}>Diamonds 1ct and above</Link>
                  </li>
                  <li className="h-12 my-3">
                    <Link
                      to={
                        "/blog/the-gia-difference-why-certification-matters-when-selling-your-diamonds/"
                      }
                    >
                      GIA Certified Diamonds
                    </Link>
                  </li>
                  <li className="h-12 my-3">
                    <Link to={"/how-to-sell-your-engagement-ring-the-best-advice/"}>
                      Engagement Rings
                    </Link>
                  </li>
                  <li className="h-12 my-3">
                    <Link to={"/surfside-buy-gold-near-me/"}>Gold Jewelry</Link>
                  </li>
                  <li className="h-12 my-3">
                    <Link
                      to={
                        "/blog/from-holiday-heirlooms-to-holiday-cash-unlocking-the-value-in-your-inherited-jewelry/"
                      }
                    >
                      Gold Coins & Bullions
                    </Link>
                  </li>{" "}
                  <li className="h-12 my-3">
                    <Link to={"/blog/key-steps-to-selling-your-old-jewelry/"}>
                      Gold Scrap and Broken Jewelry
                    </Link>
                  </li>
                  <li className="h-12 my-3">
                    <Link
                      to={
                        "/blog/silver-linings-navigating-the-current-market-for-your-sterling-silver-flatware/"
                      }
                    >
                      Sterling Silver Flatware
                    </Link>
                  </li>
                  <li className="h-12 my-3">
                    <Link
                      to={
                        "/blog/factors-that-affect-the-value-of-your-sterling-silver-flatware-and-tea-sets/"
                      }
                    >
                      Sterling Silver Tea Sets & Holloware
                    </Link>
                  </li>{" "}
                  <li className="h-12 my-3">
                    <Link to={"/blog/does-david-yurman-hold-its-value-jewelry-buyer-tips/"}>
                      Designer Silver Jewelery
                    </Link>
                  </li>
                  <li className="h-12 my-3">
                    <Link to={"/sell-my-pocket-watch/"}>Pocket Watches</Link>
                  </li>{" "}
                  <li className="h-12 my-3">
                    <Link to={"/sell-cartier-sunglasses/"}>Designer Sunglasses</Link>
                  </li>{" "}
                  <li className="h-12 my-3">
                    <Link to={"/tiffany-pins-buyer/"}>Pins and Broaches</Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </section>
      </section>

      <FAQs className={"my-20"} />
      <section className="py-10 flex flex-col lg:flex-row w-full  ">
        <div className="flex flex-col items-center justify-center lg:w-1/2 container mx-auto relative group py-10">
          <div className="bg-yellow-100 h-full w-full skew-x-[20deg] absolute top-0 -left-[35vw] -z-[1] group-hover:-left-[30vw] duration-300 ease-out"></div>
          <div className=" w-10/12 max-w-max text-center flex flex-col md:flex-row lg:flex-col xl:flex-row items-center justify-center mx-auto bg-white p-5 shadow-lg relative">
            <div className="aspect-square max-w-[350px] w-full mx-auto">
              <StaticImage
                loading="lazy"
                placeholder="blurred"
                src={"../../images/sell-watch-viktoria.jpg"}
                alt={"Viktoria Peshkur Profile Picture"}
                className={"object-contain w-full "}
                sizes="(max-width: 768px) 90vw, 420px"
              />
              <p className="text-center text-xl font-bold uppercase py-2">Viktoria Peshkur</p>
            </div>
            <div
              className={
                " h-full my-5 text-center w-full whitespace-pre-line p-5 max-w-[350px] flex flex-col justify-center items-center relative"
              }
            >
              Call ME NOW!{"\n"}
              <a href="tel:+13057706955" className="whitespace-nowrap">
                305-770-6955
              </a>
              {"\n"}
              9am – 9pm (ET){"\n"}- - - - -{"\n"}
              After 9 pm text your contact information and pictures of your item to{" "}
              <a href="tel:+17862664763" className="whitespace-nowrap">
                786-266-4763
              </a>
              <p className="absolute bottom-0">For a fast response simply</p>
              <button
                className={
                  " text-white font-bold bg-[#816230] hover:bg-accentMd absolute -bottom-14 md:-bottom-[5.5rem] lg:-bottom-14 xl:-bottom-10 left-[50%] -translate-x-[50%] uppercase px-5 py-2 rounded-full duration-300 max-w-max"
                }
                to="/what-are-you-looking-to-sell/"
              >
                CHAT NOW
              </button>
            </div>
          </div>
        </div>
        <div className="w-full lg:w-1/2 aspect-video bg-blue-200 relative">
          <div className="aspect-video w-full md:w-[400px] bg-white border border-blue-200 p-5 md:absolute md:-top-5 md:left-0 lg:-left-10 z-10 shadow-lg flex items-center justify-center">
            <p className="p-5 whitespace-pre-line leading-normal">
              <b className="text-xl text-gray-600 uppercase">Showroom hours:</b>
              <br />
              9595 Harding Ave{"\n"}
              Surfside (Miami Beach) FL 33154{"\n"}
              <i className="text-sm">(Located across from Bal Harbour Shops){"\n"}</i>
              <b>Store Hours:</b>
              <br />
              Monday - Saturday: 10am to 5pm
              <br />
              <i className="text-sm">
                <nobr>(Open Sundays 11:00am - 5:00pm</nobr> <nobr>during the holiday season,</nobr>{" "}
                <nobr>from Thanksgiving through New Year{"'"}s Eve)</nobr>
              </i>
            </p>
          </div>
          <Map className={"w-full h-full aspect-square object-center "} />
        </div>
      </section>

      <section className={"container mx-auto visibility-auto"}>
        <h2 className={"px-10  py-10 text-center text-4xl uppercase lg:py-10"}>
          Our recent reviews
        </h2>
        <Testimonials />
      </section>

      <h2 className="p-10 text-center text-3xl visibility-auto">In the press</h2>
      <div className={"container mx-auto flex gap-4"}>
        <section title={"Press Releases Posts list"} className={"flex w-full flex-wrap"}>
          {blogs.nodes.map(blog => {
            return (
              <article key={blog.slug} className={"block w-full p-4 md:w-1/2 lg:w-1/3"}>
                <Link to={"/" + blog.slug + "/"} className={"mx-auto block w-80 "}>
                  {blog.hero_image_url || blog.hero_image?.url ? (
                    <HeroImage
                      url={blog.hero_image_url || blog.hero_image.url}
                      alt={blog.meta_title}
                      className={"h-80 w-80 rounded-2xl object-contain"}
                    />
                  ) : (
                    <StaticImage
                      src={"../../images/top-watch-buyer-img.png"}
                      alt={blog.meta_title}
                      className={"h-80 w-80 rounded-2xl object-cover"}
                    />
                  )}
                </Link>
                <h2 className="mx-auto w-80 py-4 ">
                  <Link className="text-black" to={"/" + blog.slug + "/"}>
                    {blog.title}
                  </Link>
                </h2>
                <Link
                  className={"mx-auto block w-80 group text-black"}
                  aria-label={blog.title}
                  to={"/" + blog.slug + "/"}
                >
                  {blog.meta_description}
                  <span className="group-hover:tracking-wide group-hover:text-accentMd text-accent duration-200 ease-in-out pt-4 block">
                    Read More
                  </span>
                </Link>
              </article>
            )
          })}
        </section>
      </div>
      <Link to="/in-the-press/" className={"btn-cta p-6 block w-80 mx-auto"}>
        View all press releases
      </Link>

      <h2 className="p-10 text-center text-3xl visibility-auto">
        See us last! We will{" "}
        <Link to="/what-is-my-sterling-silver-worth/">offer the best price for the silverware</Link>
        , scrap sterling silver, or silver coins you want to sell.
      </h2>

      <section className=" w-full justify-center pt-10">
        <div className="flex w-full flex-col items-center justify-center ">
          <a href="tel:+13057706955" className="text-3xl font-bold text-accent hover:text-gray-500">
            <h3 className="px-10 text-center text-3xl uppercase">Call Us Now: 305 770 6955</h3>
          </a>
          <div className="w-full shadow-xl h-10" />
          <StaticImage
            src={"../../images/sell-us-your-jewelry-logo.png"}
            className="w-10/12 max-w-[500px] mx-auto my-10"
            alt={"Gray and Sons Jewelers"}
          />
          <h2 className=" uppercase w-10/12 max-w-[1366px] mx-auto text-center text-accentMd">
            The Purchasing and Consignment Division of Gray &amp; Sons
          </h2>

          <div className="grid grid-cols-1 lg:grid-cols-2 gap-5 w-10/12 max-w-[1366px] mx-auto py-10">
            <div>
              <StaticImage
                src={"../../images/trade-consign-luxury-watch.jpg"}
                className="w-10/12 lg:w-full mx-auto"
                alt={"Gray and Sons Jewelers"}
              />
            </div>
            <div className="leading-loose">
              <h2 className="font-bold text-xl mx-auto">
                Unlock the Benefits of Trading in and Upgrading Your Jewelry or Watch
              </h2>
              <p>
                Have you ever considered trading in your current watch or jewelry piece for
                something new and exciting? Trading in is not just an efficient way to{" "}
                <b>refresh your collection</b>; it also comes with a host of practical benefits that
                can make upgrading more rewarding than ever. At{" "}
                <a
                  target="_blank"
                  href="https://grayandsons.com/"
                  className="font-bold text-red-700"
                >
                  Gray and Sons Jewelers
                </a>
                , we make it easy for you to exchange your treasured items for something that
                perfectly fits your evolving taste.
                <br />
                Perhaps one of the most compelling advantages of trading in is the ability to{" "}
                <b>negotiate better pricing</b>. By using your current watch or jewelry as part of
                the payment, you reduce the overall cost of your new purchase, allowing you to
                stretch your budget further. This not only makes luxury more accessible but also
                empowers you to invest in higher-value items with minimal financial strain. Another
                significant benefit is the opportunity to <b>save on sales tax</b>. In many cases,
                the taxable amount of your purchase is reduced by the value of your trade-in,
                resulting in substantial savings. Over time, these tax advantages can add up, making
                trading an even smarter choice for professional buyers looking to maximize their
                investment.
                <br />
                Finally, trading in your un-used jewelry is a way to refresh your collection without
                hurting your wallet. Instead of letting unused pieces collect dust, you can give
                them a second life and enjoy the satisfaction of an item that better suits you
                current lifestyle. With our seamless and transparent trading process, upgrading your
                watch or jewelry has never been easier or more rewarding. Ready to explore your
                options? <br />
                <b>Contact us today</b> to learn how trading in your jewelry or watch can pave the
                way for your next extraordinary purchase! Click the button below to browse our
                inventory and find your next treasure.
              </p>
            </div>
          </div>
          <a
            target="_blank"
            href="https://www.grayandsons.com/what-s-new/"
            className="col-span-2  mx-auto bg-red-800 text-white font-bold px-5 py-2 lg:hover:bg-red-700"
          >
            BROWSE INVENTORY
          </a>
        </div>
      </section>
    </>
  )
}

export default IndexBelowTheFold
