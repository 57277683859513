import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { useCallback } from "react"
import { graphql, useStaticQuery } from "gatsby"
import { Link } from "gatsby"
import Layout from "../components/Layout"
import Seo from "../components/layout/Seo"
import Map from "../components/common/Map"
import WhatAreYourOptions from "../components/common/WhatAreYourOptions"
import FormWrapper from "../components/form/FormWrapper"
import useFormState from "../components/form/hooks/useFormState"
import InputField from "../components/form/InputField"
import FileUpload from "../components/form/FileUpload"
import HaveQuestions from "../components/common/HaveQuestions"
import HowItWorks from "../components/common/HowItWorks"
import cascade from "../components/form/validators/cascade"
import isNotEmpty from "../components/form/validators/isNotEmpty"
import isBrandWeBuy from "../components/form/validators/isBrandWeBuy"
import FeaturedProductsBlock from "../components/products/FeaturedProductsSlider"
import SideQuoteForm from "../components/layout/SideQuoteForm"

const CurrentDevDL = () => {
  const [form, setForm, updateForm] = useFormState({
    name: "",
    phone: "",
    brand: "",
    email: "",
    description: "",
    photos: [],
  })
  const updateUploadedFiles = useCallback(
    files =>
      setForm(f => {
        return { ...f, photos: files }
      }),
    [setForm]
  )
  const [locationCategory, setLocationCategory] = React.useState("jewelry")
  const { watches, jewelry, watchProducts, jewelryProducts } = useStaticQuery(
    graphql`
      query CombinedLocationpageQuery {
        watchProducts: allStrapiProduct(
          sort: { fields: stock_date, order: DESC }
          limit: 12
          filter: { item_type: { eq: "Watch" } }
        ) {
          nodes {
            name
            productType
            categories {
              name
              id
            }
            url_key
            sku
            title
            image {
              name
              url
            }
            thumbnail {
              name
              url
            }
            brand
            model
            price
          }
        }
        jewelryProducts: allStrapiProduct(
          sort: { fields: stock_date, order: DESC }
          limit: 12
          filter: { item_type: { eq: "Jewelry" } }
        ) {
          nodes {
            name
            productType
            categories {
              name
              id
            }
            url_key
            sku
            title
            image {
              name
              url
            }
            thumbnail {
              name
              url
            }
            brand
            model
            price
          }
        }
        watches: allStrapiPage(
          filter: { tag: { watches: { eq: true } } }
          sort: { fields: tag___brand }
        ) {
          nodes {
            slug
            tag {
              brand
            }
          }
        }
        jewelry: allStrapiPage(
          filter: { tag: { jewelry: { eq: true } } }
          sort: { fields: tag___brand }
        ) {
          nodes {
            slug
            tag {
              brand
            }
          }
        }
      }
    `
  )
  const products = locationCategory === "jewelry" ? jewelryProducts : watchProducts
  console.log(products)
  products.nodes.forEach(product => {
    product.url = "#quote-form"
  })
  return (
    <Layout className={"mx-auto"} breadcrumbs={false}>
      <Seo
        title="What Are You Looking to Sell? | #1 Buyer in the US"
        description="What are you looking to sell? Sell your watches and jewelry for the most money. Fast, safe, and secure. The best online luxury goods buyer located in ..."
        canonical={"/what-are-you-looking-to-sell/"}
      />
      <section className="w-10/12 mx-auto max-w-[1366px] grid md:grid-cols-2 gap-4 py-5 lg:py-10">
        <div className="w-full flex justify-center items-center">
          <StaticImage
            placeholder="none"
            loading="lazy"
            src={"../images/watch-quote-btn.jpeg"}
            alt={"The solution to selling your jewelry"}
            className={"m-auto transform duration-200 group-hover:-translate-y-2"}
          />
        </div>
        <div className=" w-full flex flex-col gap-5">
          <h1>Top Used Jewelry Buyers in Aventura, FL</h1>
          <h2>Over 45 years of experience buying top brand, luxury and fine jewelry.</h2>
          <p>
            Welcome to Gray and Sons, where luxury meets longevity in the heart of Aventura,
            Florida. As a premier provider of Used Rolex Watches Aventura and other certified
            pre-owned luxury timepieces, we offer an unmatched blend of quality and service. Explore
            our extensive collection of Pre-Owned Rolex Watches and experience first-class customer
            care tailored to your individual needs.
          </p>
          <a
            href="#quote-form"
            className="max-w-max font-bold text-black bg-accentMd px-5 py-3 rounded-full duration-200 hover:text-white border-b-2 border-accent hover:shadow-lg hover:-translate-y-1"
            to="/brands-we-buy/"
          >
            {locationCategory === "jewelry" ? "QUOTE MY JEWELRY" : "QUOTE MY WATCH"}
          </a>
        </div>
      </section>
      <section className="w-full bg-black text-white uppercase ">
        <div className="w-10/12 mx-auto max-w-[1366px] text-white py-10">
          <h2 className="">{locationCategory === "jewelry" ? "JEWELRY" : "WATCH"} BRANDS WE BUY</h2>
          <ul
            className={`columns-2 lg:columns-3 gap-x-4 pb-5 ${
              (locationCategory === "jewelry" && jewelry.nodes.length > 36) ||
              (locationCategory === "watches" && watches.nodes.length > 36)
                ? "xl:columns-4"
                : ""
            }`}
          >
            {locationCategory === "jewelry"
              ? jewelry.nodes.map(jewelryItem => {
                  return (
                    <Link
                      className="max-w-max py-3 text-white md:text-lg md:hover:text-accentMd md:hover:translate-x-1 duration-200 block"
                      to={`/${jewelryItem.slug}/`}
                    >
                      {jewelryItem.tag.brand}
                    </Link>
                  )
                })
              : watches.nodes.map(watches => {
                  return (
                    <Link
                      className="max-w-max py-3 text-white md:text-lg md:hover:text-accentMd md:hover:translate-x-1 duration-200 block"
                      to={`/${watches.slug}/`}
                    >
                      {watches.tag.brand}
                    </Link>
                  )
                })}
          </ul>
          <a
            href="#quote-form"
            className="font-bold text-black bg-accentMd px-5 py-3 rounded-full mt-5 duration-200 hover:text-white border-b-2 border-accent hover:border-white hover:-translate-y-1"
            to="/brands-we-buy/"
          >
            {locationCategory === "jewelry" ? "GET JEWELRY QUOTE" : "GET WATCH QUOTE"}
          </a>
        </div>
      </section>
      <section className="w-11/12 max-w-[1366px] mx-auto flex justify-center items-center py-10 px-5">
        <FeaturedProductsBlock
          products={products.nodes}
          to={"#quote-form"}
          buttonText="GET QUOTE"
          title={`RECENTLY PURCHASED ${locationCategory}`}
        />
      </section>
      <section className="bg-[#f6ebd0] flex flex-col justify-center items-center py-10 relative">
        <div className="w-11/12 max-w-[1366px] mx-auto flex flex-col md:flex-row gap-5 ">
          <div className="w-full max-w-[450px] h-full aspect-square bg-red-200 lg:sticky lg:top-20">
            City Image
          </div>
          <div className="w-full leading-loose">
            City Info Many of our Aventura Customers drive to our Showroom to complete their
            transaction right away as well as to view our entire inventory. Many of our Aventura
            Customers drive to our Showroom to complete their transaction right away as well as to
            view our entire inventory. Many of our Aventura Customers drive to our Showroom to
            complete their transaction right away as well as to view our entire inventory. Many of
            our Many of our Aventura Customers drive to our Showroom to complete their transaction
            right away as well as to view our entire inventory. Many of our Aventura Customers drive
            to our Showroom to complete their transaction right away as well as to view our entire
            inventory. Many of our Aventura Customers drive to our Showroom to complete their
            transaction right away as well as to view our entire inventory. Many of our Aventura
            Customers drive to our Showroom to complete their transaction right away as well as to
            view our entire inventory. Many of our Aventura Customers drive to our Showroom to
            complete their transaction right away as well as to view our entire inventory. Many of
            our Aventura Customers drive to our Showroom to complete their transaction right away as
            well as to view our entire inventory. Many of our Aventura Customers drive to our
            Showroom to complete their transaction right away as well as to view our entire
            inventory. Many of our Aventura Customers drive to our Showroom to complete their
            transaction right away as well as to view our entire inventory. Many of our Aventura
            Customers drive to our Showroom to complete their transaction right away as well as to
            view our entire inventory. Many of our Aventura Customers drive to our Showroom to
            complete their transaction right away as well as to view our entire inventory. Many of
            our Aventura Customers drive to our Showroom to complete their transaction right away as
            well as to view our entire inventory. Many of our Aventura Customers drive to our
            Showroom to complete their transaction right away as well as to view our entire
            inventory. Many of our Aventura Customers drive to our Showroom to complete their
            transaction right away as well as to view our entire inventory. Many of our Aventura
            Customers drive to our Showroom to complete their transaction right away as well as to
            view our entire inventory. Many of our Aventura Customers drive to our Showroom to
            complete their transaction right away as well as to view our entire inventory. Many of
            our Aventura Customers drive to our Showroom to complete their transaction right away as
            well as to view our entire inventory. Many of our Aventura Customers drive to our
            Showroom to complete their transaction right away as well as to view our entire
            inventory. Many of our Aventura Customers drive to our Showroom to complete their
            transaction right away as well as to view our entire inventory.
          </div>
        </div>
        <p className="py-5">
          Many of our Aventura Customers drive to our Showroom to complete their transaction right
          away as well as to view our entire inventory.
        </p>
      </section>
      <WhatAreYourOptions />
      <HowItWorks className={"w-11/12 mx-auto max-w-[1366px] border-t border-b py-10 my-10"} />
      <section
        id="quote-form"
        className="pt-16 w-11/12 mx-auto max-w-[1366px] flex flex-col lg:flex-row"
      >
        <div className="w-full  mx-auto max-w-[600px] text-center">
          <h1 className="w-full text-3xl uppercase pt-3">
            WHAT IS MY {locationCategory === "jewelry" ? "JEWELRY" : "WATCH"} WORTH?
          </h1>
          <p>
            Fill out this simple form to receive a free{" "}
            {locationCategory === "jewelry" ? "jewelry" : "watch"} quote!
          </p>
          {locationCategory === "jewelry" ? (
            <StaticImage
              src={"../images/sell-my-jewelry-form-header.png"}
              alt={"Watch Quote Form image"}
              loading={"lazy"}
              placeholder={"none"}
              className="w-11/12 mx-auto "
            />
          ) : (
            <StaticImage
              src={"../images/sell-my-watch-form-header.png"}
              alt={"Watch Quote Form image"}
              loading={"lazy"}
              placeholder={"none"}
              className="w-11/12 mx-auto "
            />
          )}
        </div>
        <SideQuoteForm defaultType={locationCategory} className="w-11/12 max-w-[800px] mx-auto" />
      </section>

      <Map className={"mt-16 h-120 "} />
      <HaveQuestions />
    </Layout>
  )
}

export default CurrentDevDL
